@import "mixins";

.hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: calc(100vh - 75px);
    min-height: 650px;

    @include mobile {
        min-height: unset;
        height: unset;
        flex-direction: column;
        padding-top: 126px;
    }

    &__left {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 3 0;
        height: 100%;

        @include mobile {
            align-items: center;
        }

        &_subtitle {
            margin-top: 25px;
            font: var(--font-19-25-medium);
            color: var(--color-secondary);
            max-width: 25pc;

            @include mobile {
                text-align: center;
            }
        }

        &_clients_title {
            margin-top: 30px;
            font: var(--font-14-25-medium);
            color: var(--color-secondary);
            @include mobile {
                display: none;
            }
        }
        &_clients {
            margin-top: 15px;
            display: flex;
            align-items: center;

            @include mobile {
                display: none;
            }
            &__client {
                height: 50px;
                margin-right: 10px;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2 0;
        height: 100%;
        position: relative;

        @include mobile {
            margin-top: 40px;
        }

        &__background {
            position: absolute;

            @include mobile {
                display: none;
            }
        }

        &_form {
            z-index: 100;
        }
    }
}

.problem {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mobile {
        margin-top: 100px;
    }

    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 80px -20px 0;

        @include mobile {
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
        }
    }

    &__problem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0;
        margin: 0 20px;

        @include mobile {
            margin-top: 50px;
            align-items: center;
        }

        &_image {
            max-width: 100%;
        }

        &_title {
            margin-top: 25px;
            font: var(--font-30-36-medium);
            color: var(--color-regular);
            max-width: 22pc;

            @include tablet {
                font: var(--font-26-30-medium);
                text-align: center;
            }
        }

        &_description {
            margin-top: 15px;
            font: var(--font-19-25-medium);
            color: var(--color-secondary);
            max-width: 22pc;

            @include tablet {
                font: var(--font-17-22-medium);
                text-align: center;
            }
        }
    }
}

.solution {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 120px;

    $step: 10%;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 50px 0 -30px;

        @include mobile {
            margin: 50px 0 -25px;
        }
    }

    &__solution {
        display: flex;
        align-items: center;
        max-width: 70%;
        margin-top: 30px;
        margin-bottom: 30px;

        &:nth-child(2) {
            margin-left: $step;
        }
        &:nth-child(3) {
            margin-left: $step * 2;
        }
        &:nth-child(4) {
            margin-left: $step * 3;
        }

        @include mobile {
            flex-direction: column-reverse;
            margin-top: 25px;
            margin-bottom: 25px;
            max-width: unset;
            width: 100%;
            margin-left: 0 !important;
        }

        &_image {
            max-width: 200px;

            @include tablet {
                max-width: 200px;
            }
        }

        &_content {
            display: flex;
            flex-direction: column;
            margin-right: 80px;

            @include mobile {
                margin-right: 0;
                margin-top: 25px;
            }
        }

        &_title {
            font: var(--font-30-36-medium);
            color: var(--color-regular);
            @include tablet {
                font: var(--font-26-30-medium);
            }

            @include mobile {
                text-align: center;
            }
        }

        &_description {
            font: var(--font-19-25-medium);
            color: var(--color-secondary);
            margin-top: 15px;
            max-width: 30pc;
            @include tablet {
                font: var(--font-17-22-medium);
            }

            @include mobile {
                text-align: center;
            }
        }
    }
}

.demo-strip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    max-width: var(--max-width);

    @include mobile {
        flex-direction: column;
        padding: 20px 0;
    }

    &-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 60px;
        background: var(--color-regular);
        margin-top: 80px;

        @include mobile {
            height: unset;
        }
    }
    &_title {
        font: var(--font-20-24-medium);
        color: var(--color-white);
        text-transform: uppercase;

        @include mobile {
            font: var(--font-15-18-medium);
            text-align: center;
        }
    }
    &_button {
        margin-left: 40px;

        @include mobile {
            margin-left: 0;
            margin-top: 15px;
        }
    }
}

.demo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 120px;
    width: 100%;

    @include mobile {
        justify-content: center;
    }

    &_title {
        max-width: 25pc;
    }

    &-wrapper {
        overflow: visible;
    }

    &_content {
        display: flex;
        flex-direction: column;
    }

    &_image-wrapper {
        display: flex;
        flex: 1 0;
        height: 315px;

        @include tablet {
            height: 195px;
        }

        @include mobile {
            display: none;
        }
    }

    &_form {
        margin: 70px 130px 0;

        display: flex;
        align-content: center;
        justify-content: center;
        position: relative;

        @include mobile {
            margin: 0;
        }

        &-wrapper {
            margin-top: 50px;
        }

        &_background {
            position: absolute;

            @include mobile {
                display: none;
            }
        }
    }
}

.popup {
    display: none;

    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000000;

    &-backdrop {
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.6;
    }

    &-window {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 16px;
        padding: 25px 30px 35px;
        position: relative;
        width: 100%;
    }
    &-window-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 0 16px;
        max-width: 100%;
    }

    &-close-main {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        transition: 200ms ease;

        &:hover {
            transform: translate(50%, -50%) scale(1.1);
        }
    }
}

.demo-popup {
    &__window {
        &-title {
            font: var(--font-24-24-medium);
            color: var(--color-regular);
            margin-bottom: 32px;
        }
    }
}

.ok-popup {
    &__window-text {
        display: flex;
        font: var(--font-26-30-medium);
        color: var(--color-regular);
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 360px;
        max-width: 300px;
    }
}

.cookies {
    &-banner-wrapper {
        display: none;
        width: 100%;
        background: rgba(102, 102, 102, 0.8);
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10000000000;
    }

    &-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
    }

    &-text {
        font: var(--font-19-25-medium);
        color: #ffffff;

        .link {
            color: #ffffff;
            text-decoration: underline;
            font-weight: bold;
        }
    }

    &-button {
        display: flex;
        background: #ffffff;
        border-radius: 48px;
        border: 1px solid var(--color-secondary);
        align-items: center;
        justify-content: center;
        font: var(--font-14-18-medium);
        color: var(--color-regular);
        width: 52px;
        height: 34px;
        cursor: pointer;
        transition: 200ms ease;
        flex-shrink: 0;

        &:hover {
            transform: scale(1.1);
        }
        &:active {
            transform: scale(1.02);
        }
    }
}
